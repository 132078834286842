<script>
import Detail from "./detail";
export default {
  props: {
    currentEmpresa: { type: Object, required: true },
    listmovi_caixas: { type: Array },
    caixas2:{type: Array},
    hide: { type: Boolean, required: true },
    user: {type: Object}
  },
  components: { Detail },
  data() {
    return {
      titleBody: 'Caixas Abertos',
      currentCaixa: undefined,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: false,
      fields: [
        { label: "ID", key: "id", sortable: true,  thClass: 'text-left' },
        { label: 'Caixa Aberto', key: 'aberto', sortable: true, thClass: 'text-left' },
        { label: "Usuário", key: "user_abre.name", sortable: true, thClass: 'text-left' },
        // { label: "Ativo", key: "status", sortable: true, thClass: 'text-center' },
        { label: "Ações", key: "acoes", sortable: false, tdClass: 'text-center', thClass: 'text-center' }
      ],
    }
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return (this.listmovi_caixas.length) ? this.listmovi_caixas.length : 0;
    },
    clonefoot() {
      return (this.listmovi_caixas.length || this.listmovi_caixas.length > 10) ? true : false;
    },
  },
  mounted() {
    this.$emit('newTitle', this.titleBody);
     console.log(this.listmovi_caixas)
    // console.log(this.caixas2)
  },
  methods: {
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    setNewTitle() {
      this.$emit("newTitle", this.titleBody);
    },
    editar(historico) {
      this.$emit('edit', historico);
    },
    fechar(caixa) {
      //console.log(caixa)
      this.$emit('doFechar', caixa);
    },
    sangria(sangria){
       this.$emit('doSangria',sangria)
    },
    suprimento(suprimento){
      this.$emit('doSuprimento',suprimento)
    },
    setHistoricoPadrao(caixa) {
      this.currentCaixa = caixa;
    },
  },
}
</script>

<template>
  <div class="card-body" v-if="hide">
    <div class="row mt-4">
      <!-- Start Limit -->
      <div class="col-sm-12 col-md-6">
        <div id="historico-padrao-table_length" class="dataTables_length">
          <label class="d-inline-flex align-items-center">
            Exibir&nbsp;
            <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;registros
          </label>
        </div>
      </div>
      <!-- End Limit -->
      <!-- Start Search -->
      <div class="col-sm-12 col-md-6">
        <div id="historico-padrao-table_filter" class="dataTables_filter text-md-right">
          <label class="d-inline-flex align-items-center">
            Pesquisar:
            <b-form-input v-model="filter" type="search" placeholder="Pesquisar..."
              class="form-control form-control-sm ml-2"></b-form-input>
          </label>
        </div>
      </div>
      <!-- End search -->
    </div>
    <!-- Start Table -->
    <div class="row mt-4">
      <div v-if="hide" class="table-responsive mb-0">
        <b-table :items="listmovi_caixas" :fields="fields" responsive="sm" :per-page="perPage" :current-page="currentPage"
          :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :filter="filter" :filter-included-fields="filterOn"
          @filtered="onFiltered" :hover=true :foot-clone=clonefoot>
          <!-- <template #cell(user_name)="row">
            {{ (row.item.user) ? row.item.user.name : 'Usuário não especificado' }}
          </template> -->
          <template #cell(user_abre)="row">
            {{ (row.item.user_abre.name) ? `${row.item.user_abre.name}` : `${row.item.user_abre.name}` }}
          </template>
          <template #cell(status)="row">
            {{ (row.item.status === 1) ? 'Sim' : 'Não' }}
          </template>
          <template #cell(aberto)="row">
            {{ (row.item.aberto === 1) ? 'Aberto' : 'Fechado' }}
          </template>
          <template #cell(acoes)="row">
            <!-- `data.value` is the value after formatted by the Formatter -->
            <b-dropdown v-bind:id="'dropdown-' + row.item.id" class="m-md-2">
              <template #button-content>
                <i data-v-6289eca4="" class="bx ri-menu-line"></i>
              </template>
              <b-dropdown-item v-b-modal.modal-caixa @click="setHistoricoPadrao(row.item)"><i
                  class="bx ri-eraser-fill"></i> Visualizar</b-dropdown-item>
              <b-dropdown-item @click="sangria(row.item)"><i class="bx ri-edit-2-line"></i> Sangria</b-dropdown-item>
              <!-- <b-dropdown-item @click="editar(row.item)"><i class="bx ri-edit-2-line"></i> Suprimento </b-dropdown-item> -->
              <b-dropdown-item @click="suprimento(row.item)"><i class="bx ri-edit-2-line"></i> Suprimento </b-dropdown-item>
              <b-dropdown-item @click="fechar(row.item)"><i class="bx ri-eraser-fill"></i> Fechar caixa</b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
      </div>
    </div>
    <!-- End Table -->
    <!-- Start Pagination -->
    <div v-if="hide" class="row mt-4">
      <div class="col">
        <div class="dataTables_paginate paging_simple_numbers float-right">
          <ul class="pagination pagination-rounded mb-0">
            <!-- pagination -->
            <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
          </ul>
        </div>
      </div>
    </div>
    <!-- End Pagination -->
    <!-- Start Modal Centro Custo -->
    <b-modal v-if="hide" hide-footer id="modal-caixa" size="xl" :title="'Movimento de Caixa'" title-class="font-18">
      <Detail :caixa="currentCaixa" />
    </b-modal>
    <!-- End Modal Centro Custo -->
  </div>
</template>