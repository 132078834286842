

<script>
//import Multiselect from "vue-multiselect";
export default {
    name: "tela_informacao_abertura",
    props: {
        empresa: { type: Object, required: true },
        showModal: { type: Boolean }
    },
    components: {
        // Multiselect,
    },
    data() {
        return {
            titleBody: "Abertura de Caixa",

            empresa_caixa: null,

            vetor: [
                'valor1',
                'valor2',
                'valor3'
            ],

            caixa: {
                saldo_inicial: 0,
                data_abre: null,
                caixa_id: 1,
                user_id_fecha: 0,
                user_id_abre: 1,
                total_cupom: 0,
                cartao: 0,
                prazo: 0,
                duplicata: 0,
                duplicata_info: 0,
                cheque: 0,
                desconto: 0,
                pago: 0,
                troco: 0,
                sangria: 0,
                suprimento: 0,
                total_geral: 0,
                aberto: 1,
                cancelamentos: 0,
                cartao_info: 0,
                prazo_info: 0,
                cheque_info: 0,
                dinheiro:0,
                dinheiro_info: 0,
                pix: 0,
                pix_info: 0,
                picpay: 0,
                picpay_info: 0,
                transferencia: 0,
                transferencia_info: 0,
                cartao_debito: 0,
                cartao_debito_info: 0,
            },
            caixa2: {

            },
            suprimento: 0,
            saldo_final: null,
        }
    },
    computed: {
        total2() {
            return parseFloat(this.caixa.saldo_inicial)
        },
        dataAtual() {
            return new Date().toISOString().substr(0, 10); // Obter a data atual no formato "yyyy-mm-dd"
        }
    },
    mounted() {
        this.$emit('newTitle', this.titleBody);
        this.empresa_caixa = this.empresa
        this.caixa.data_abre = this.dataAtual
        console.log(this.empresa_caixa)
    },
    methods: {
        formatInput_saldo_inicial() {
            // Remova todos os caracteres não numéricos do valor de entrada
            let numericValue = this.caixa.saldo_inicial.replace(/\D/g, '');
            // Formate o valor numérico para a moeda desejada (por exemplo, BRL)
            this.caixa.saldo_inicial = parseFloat(numericValue / 100).toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
            }).replace("R$", " ");
        },
        coverte_para_float_valor_input() {
            this.caixa2 = this.caixa
            // fiz isso pois se usa a o atrivuto caiax.dinheiro info diretamente, 
            // na conversão para real não funciona;
            let stringValor = this.caixa.saldo_inicial;
            let valorFlutuante = parseFloat(stringValor.replace("R$", " ").replace(/\./g, "").replace(",", "."));
            this.caixa2.saldo_inicial = valorFlutuante
        },
        total() {
            if (isNaN(this.total2)) {
                this.caixa.total_geral = this.saldo_final = 0;
            }
            this.caixa.total_geral = this.total2;
            // console.log(this.caixa.saldo_final);
        },
        setPost() {
            //this.total()
            this.coverte_para_float_valor_input();
            this.caixa2.user_id_abre=1;
            this.caixa2.user_id_fecha =0;
            // soma o saldo inicial com o total geral;
            this.caixa2.total_geral = this.caixa2.total_geral + this.caixa2.saldo_inicial
            this.$emit('doPost', this.caixa2);
        },
        voltar() {
            this.$emit('back');
        }
    },
}
</script>

<template>
    <b-modal v-model="showModal" id="modal-xl" size="xl" title="Abertura de Caixa" @hidden="voltar()" hide-footer>
        <div class="card">
            <div class="card-body">
                <form id="form-centro-resultado" role="form" class="form-horizontal">
                    <div class="form-row form-group" role="group">
                        <div class="col-sm-12 col-md-12">
                            <div class="form-group">
                                <label for="centro_resultado_id" class="col-form-label">Empresa</label>
                                <div class="col">
                                    <input disabled v-model="empresa_caixa.fantasia" class="form-control text-left"
                                        type="text" placeholder="Gerado pelo sistema">
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-12">
                            <div class="form-group">
                                <label for="Periodo_id" class="col-form-label">Periodo</label>
                                <div class="col">
                                    <div>
                                        <b-form-datepicker disabled v-model="caixa.data_abre" id="data"
                                            placeholder="selecionar Data"></b-form-datepicker>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-12">
                            <div class="form-group">
                                <label for="centro_resultado_id" class="col-form-label">Saldo inicial</label>
                                <div class="col">
                                    <input v-model="caixa.saldo_inicial" @input="formatInput_saldo_inicial()"
                                        class="form-control text-right" type="text" placeholder="Gerado pelo sistema">
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-12">
                            <div class="form-group">
                                <label for="centro_resultado_id" class="col-form-label">Total em Caixa</label>
                                <div class="col">
                                    <input v-model="caixa.saldo_inicial" class="form-control text-right" type="text"
                                        placeholder="Gerado pelo sistema">
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div class="card-body" id="top">
                <div class="col-md-12 mt-10">
                    <button class="btn btn-light" id="butt" @click="setPost()">
                        Abrir Caixa
                    </button>
                </div>
            </div>
        </div>
    </b-modal>
</template>

<style></style>