<script>
/**
 * Widget Component
 */
export default {
  props: { tot_caixa: { type: Number }, item: { type: Object } },
  data() {
    return {
      newObject: {
        nome: '',
        valor: 0,
        tipo_pagamento_id: 0
      },
      total: 0,
      statData: [
        {
          title: "Total em Caixa",
          icon: "ri-stack-line",
          value: "0,00",
          subvalue: 0
        },
        {
          title: "Valor",
          icon: "ri-store-2-line",
          value: '0,00',
          subvalue: 0
        },
        {
          title: "Tipo Pagamento",
          icon: "ri-briefcase-4-line",
          value: "selecione",
          subvalue: 0
        }
      ]
    };

  },
  created(){
    // this.statData[0].value = this.tot_caixa;
    this.newObject = this.item;
    this.total = this.tot_caixa;
  },
  watch: {
    valor(novoValor) {
      this.valor1 = novoValor
      this.statData[1].value = novoValor;
      this.statData[1].subvalue = novoValor;
    },
    pagamento(novoPagamento){
      this.statData[2].value = novoPagamento;
      this.statData[2].subvalue = novoPagamento;
    },
    // soma(novaSoma){
    //   console.log(novaSoma)
    //   if(novaSoma == true){
    //     this.statData[0].value += this.valor1
    //   }
    // }
  }
};
</script>

<template>
  <div class="row">
    <div class="col-md-4">
      <div class="card">
        <div class="card-body">
          <div class="media">
            <div class="media-body overflow-hidden">
              <p class="text-truncate font-size-14 mb-2">Total Caixa</p>
              <h4 class="mb-0">{{ formatterCurrBR(total)  }}</h4>
            </div>
            <div class="text-primary">
              <i class="ri-stack-line font-size-24"></i>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-4">
      <div class="card">
        <div class="card-body">
          <div class="media">
            <div class="media-body overflow-hidden">
              <p class="text-truncate font-size-14 mb-2">Valor R$</p>
              <h4 class="mb-0">{{ formatterCurrBR(newObject.valor)  }}</h4>
            </div>
            <div class="text-primary">
              <i class="ri-store-2-line font-size-24"></i>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-4">
      <div class="card">
        <div class="card-body">
          <div class="media">
            <div class="media-body overflow-hidden">
              <p class="text-truncate font-size-14 mb-2">Tipo Pagamento</p>
              <h4 class="mb-0">{{ newObject.tipo_pagamento.tiponome }}</h4>
            </div>
            <div class="text-primary">
              <i class="ri-briefcase-4-line font-size-24"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- end row -->
</template>