<script>
export default {
  props: {
    empresa: { type: Object, required: true },
    oldCaixa: { type: Object, required: true }
  },
  data() {
    return {
      titleBody: 'Fechamento de Caixa',
      caixa: {

      },
      caixa2: {

      },
      dinheiro_info: null,
      verificar: false
    }
  },
  created() { },
  computed:{
    dataAtual() {
            return new Date().toISOString().substr(0, 10); // Obter a data atual no formato "yyyy-mm-dd"
        }

  },
  mounted() {
    this.$emit('newTitle', this.titleBody);
   // console.log(this.oldCaixa);
    this.caixa = this.oldCaixa;
    this.caixa.data_fecha = this.dataAtual
    this.converterParaReal_valor_input2()
  },
  methods: {
    digitar_verifica() {
      this.verificar = true
    },
    // utilizado para formata os valores quando entrear na tela,
    // os valores que vendo do propos , e não os digitados;
    // cria uma função para converte para float quando for fechar o caixa,
    // pq os valores tem que ser convertidos para float;
    converterParaReal_valor_input2() {
      const valor = parseFloat(this.caixa.dinheiro_info); // Seu valor numérico
      const valor2 = parseFloat(this.caixa.cheque_info); // Seu valor numérico
      const valor3 = parseFloat(this.caixa.cartao_info); // Seu valor numérico
      const valor4 = parseFloat(this.caixa.cartao_debito_info); // Seu valor numérico
      const valor5 = parseFloat(this.caixa.duplicata_info); // Seu valor numérico
      const valor6 = parseFloat(this.caixa.pix_info); // Seu valor numérico
      const valor7 = parseFloat(this.caixa.picpay_info); // Seu valor numérico
      const valor8 = parseFloat(this.caixa.transferencia_info); // Seu valor numérico
      const formatoMoeda = {
        style: "currency",
        currency: "BRL"
      };
      // so funciona quando o numero é enviado de uma vez
      const valorFormatado = valor.toLocaleString("pt-BR", formatoMoeda);
      const valorFormatado2 = valor2.toLocaleString("pt-BR", formatoMoeda);
      const valorFormatado3 = valor3.toLocaleString("pt-BR", formatoMoeda);
      const valorFormatado4 = valor4.toLocaleString("pt-BR", formatoMoeda);
      const valorFormatado5 = valor5.toLocaleString("pt-BR", formatoMoeda);
      const valorFormatado6 = valor6.toLocaleString("pt-BR", formatoMoeda);
      const valorFormatado7 = valor7.toLocaleString("pt-BR", formatoMoeda);
      const valorFormatado8 = valor8.toLocaleString("pt-BR", formatoMoeda);

      ///console.log(valorFormatado); // Saída: R$ 1.234,56
      this.caixa.dinheiro_info = valorFormatado.replace("R$", " ")
      this.caixa.cheque_info = valorFormatado2.replace("R$", " ")
      this.caixa.cartao_info = valorFormatado3.replace("R$", " ")
      this.caixa.cartao_debito_info = valorFormatado4.replace("R$", " ")
      this.caixa.duplicata_info = valorFormatado5.replace("R$", " ")
      this.caixa.pix_info = valorFormatado6.replace("R$", " ")
      this.caixa.picpay_info = valorFormatado7.replace("R$", " ")
      this.caixa.transferencia_info = valorFormatado8.replace("R$", " ")
    },
    converterParaReal_valor_input() {
      const valor = parseFloat(this.dinheiro_info); // Seu valor numérico
      const formatoMoeda = {
        style: "currency",
        currency: "BRL"
      };
      // so funciona quando o numero é enviado de uma vez
      const valorFormatado = valor.toLocaleString("pt-BR", formatoMoeda);
      ///console.log(valorFormatado); // Saída: R$ 1.234,56
      this.dinheiro_info = valorFormatado.replace("R$", " ")
      //console.log(this.caixa.dinheiro_info)
    },
    aguardarDigitacao_valor_input() {
      if (this.caixa.dinheiro_info == null || isNaN(this.caixa.dinheiro_info)) {
        this.caixa.dinheiro_info = ''
      } else {
        clearTimeout(this.timeout); // Limpa o timeout anterior, se houver
        this.timeout = setTimeout(() => {
          this.executarAposDigitar_valor_input();
        }, 500); // Defina o tempo de espera desejado (em milissegundos)
      }
    },
    // usado para o cheque info;
    formatInput_cheque_info() {
      // Remova todos os caracteres não numéricos do valor de entrada
      let numericValue = this.caixa.cheque_info.replace(/\D/g, '');

      // Formate o valor numérico para a moeda desejada (por exemplo, BRL)
      this.caixa.cheque_info = parseFloat(numericValue / 100).toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      }).replace("R$", " ");
    },
    formatInput_cartao_info() {
      // Remova todos os caracteres não numéricos do valor de entrada
      let numericValue = this.caixa.cartao_info.replace(/\D/g, '');
      // Formate o valor numérico para a moeda desejada (por exemplo, BRL)
      this.caixa.cartao_info = parseFloat(numericValue / 100).toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      }).replace("R$", " ");
    },
    formatInput_cartao_debito_info() {
      // Remova todos os caracteres não numéricos do valor de entrada
      let numericValue = this.caixa.cartao_debito_info.replace(/\D/g, '');
      // Formate o valor numérico para a moeda desejada (por exemplo, BRL)
      this.caixa.cartao_debito_info = parseFloat(numericValue / 100).toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      }).replace("R$", " ");
    },
    formatInput_duplicata_info() {
      // Remova todos os caracteres não numéricos do valor de entrada
      let numericValue = this.caixa.duplicata_info.replace(/\D/g, '');
      // Formate o valor numérico para a moeda desejada (por exemplo, BRL)
      this.caixa.duplicata_info = parseFloat(numericValue / 100).toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      }).replace("R$", " ");
    },
    formatInput_pix_info() {
      // Remova todos os caracteres não numéricos do valor de entrada
      let numericValue = this.caixa.pix_info.replace(/\D/g, '');
      // Formate o valor numérico para a moeda desejada (por exemplo, BRL)
      this.caixa.pix_info = parseFloat(numericValue / 100).toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      }).replace("R$", " ");
    },
    formatInput_picpay_info() {
      // Remova todos os caracteres não numéricos do valor de entrada
      let numericValue = this.caixa.picpay_info.replace(/\D/g, '');
      // Formate o valor numérico para a moeda desejada (por exemplo, BRL)
      this.caixa.picpay_info = parseFloat(numericValue / 100).toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      }).replace("R$", " ");
    },
    formatInput_transferencia_info() {
      // Remova todos os caracteres não numéricos do valor de entrada
      let numericValue = this.caixa.transferencia_info.replace(/\D/g, '');
      // Formate o valor numérico para a moeda desejada (por exemplo, BRL)
      this.caixa.transferencia_info = parseFloat(numericValue / 100).toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      }).replace("R$", " ");
    },
    formatInput_dinheiro_info() {
      // Remova todos os caracteres não numéricos do valor de entrada
      let numericValue = this.caixa.dinheiro_info.replace(/\D/g, '');
      // Formate o valor numérico para a moeda desejada (por exemplo, BRL)
      this.caixa.dinheiro_info = parseFloat(numericValue / 100).toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      }).replace("R$", " ");
    },
    executarAposDigitar_valor_input() {
      // Função que será executada após o término da digitação
      this.converterParaReal_valor_input()
      this.coverte_para_float_valor_input()
    },
    coverte_para_float_valor_input() {
      this.caixa2 = this.caixa
      // fiz isso pois se usa a o atrivuto caiax.dinheiro info diretamente, 
      // na conversão para real não funciona;
      let stringValor = this.caixa.dinheiro_info;
      let valorFlutuante = parseFloat(stringValor.replace("R$", " ").replace(/\./g, "").replace(",", "."));
      this.caixa2.dinheiro_info = valorFlutuante
      //console.log(this.caixa2.dinheiro_info)
      // -------------------------------------
      let stringValor1 = this.caixa.cheque_info;
      let valorFlutuante1 = parseFloat(stringValor1.replace("R$", " ").replace(/\./g, "").replace(",", "."));
      this.caixa2.cheque_info = valorFlutuante1
      //console.log(this.caixa2.cheque_info); // Saída: 13000.00
      // ---------------------------------------

      let stringValor2 = this.caixa.cartao_info;
      let valorFlutuante2 = parseFloat(stringValor2.replace("R$", " ").replace(/\./g, "").replace(",", "."));
      this.caixa2.cartao_info = valorFlutuante2
      //console.log(this.caixa2.cartao_info);
      // -----------------------------------------------
      let stringValor3 = this.caixa.cartao_debito_info;
      let valorFlutuante3 = parseFloat(stringValor3.replace("R$", " ").replace(/\./g, "").replace(",", "."));
      this.caixa2.cartao_debito_info = valorFlutuante3
      //console.log(this.caixa2.cartao_debito_info);
      // ---------------------------------------------
      let stringValor4 = this.caixa.duplicata_info;
      let valorFlutuante4 = parseFloat(stringValor4.replace("R$", " ").replace(/\./g, "").replace(",", "."));
      this.caixa2.duplicata_info = valorFlutuante4
      //console.log(this.caixa2.duplicata_info);
      // -----------------------------------------------
      let stringValor5 = this.caixa.pix_info;
      let valorFlutuante5 = parseFloat(stringValor5.replace("R$", " ").replace(/\./g, "").replace(",", "."));
      this.caixa2.pix_info = valorFlutuante5
      //console.log(this.caixa2.pix_info);
      // ---------------------------------
      let stringValor6 = this.caixa.picpay_info;
      let valorFlutuante6 = parseFloat(stringValor6.replace("R$", " ").replace(/\./g, "").replace(",", "."));
      this.caixa2.picpay_info = valorFlutuante6
      //console.log(this.caixa2.picpay_info);
      // ---------------------------------
      let stringValor7 = this.caixa.transferencia_info;
      let valorFlutuante7 = parseFloat(stringValor7.replace("R$", " ").replace(/\./g, "").replace(",", "."));
      this.caixa2.transferencia_info = valorFlutuante7
      //console.log(this.caixa2.transferencia_info);

    },
    // ---------------  fim  tot_caixa_input--------------------------------
    // setPost() {
    //   this.$emit('doPost', this.historicoPadrao);
    // },
    setFechar() {
      this.$emit('cancelar')
    },
    setCaixa_fechar() {
      this.coverte_para_float_valor_input();
      //this.caixa2.user_id_abre = 0
      //console.log(this.caixa2);
      let caixa = this.caixa2;
      // feita altereções aqui 
      // mas aqui
       caixa.aberto =0
       console.log(caixa)
      this.$emit('caixa_fechar', caixa)
    },
  },
}
</script>
<template>
  <div class="card">
    <div class="accordion" role="tablist">
      <form role="form" class="form-horizontal">
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle.valores-informados variant="light">Valores Informado</b-button>
          </b-card-header>
          <b-collapse id="valores-informados" visible accordion="valores-informados" role="tabpanel">
            <b-card-body>
              <div class="form-row form-group" role="group">
                <div class="col-sm-12 col-md-3" id="cod_prod">
                  <div class="form-group">
                    <label for="dinheiro_in_id" class="col-form-label">Dinheiro</label>
                    <div class="col">
                      <input v-model="caixa.dinheiro_info" class="form-control text-right" type="text"
                        placeholder="digite o valor" id="dinheiro_in_id" @input="formatInput_dinheiro_info()" />
                    </div>

                  </div>
                </div>
                <div class="col-sm-12 col-md-3" id="desc_ID">
                  <div class="form-group">
                    <label for="cartao_in_id" class="col-form-label">Cheque</label>
                    <div class="col">
                      <input v-model="caixa.cheque_info" @input="formatInput_cheque_info()" placeholder="digite o numero"
                        id="cartao_in_id" type="text" class="form-control text-right">
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-3" id="desc_ID">
                  <div class="form-group">
                    <label for="cartao_in_id" class="col-form-label">Cartão</label>
                    <div class="col">
                      <input v-model="caixa.cartao_info" @input="formatInput_cartao_info()" id="cartao_in_id" type="text"
                        class="form-control text-right" placeholder="digite">
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-3" id="desc_ID">
                  <div class="form-group">
                    <label for="cheque_in_id" class="col-form-label">Cartão Debito</label>
                    <div class="col">
                      <input v-model="caixa.cartao_debito_info" @input="formatInput_cartao_debito_info()"
                        placeholder="digite" id="cheque_in_id" type="text" class="form-control text-right">
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-3" id="desc_ID">
                  <div class="form-group">
                    <label for="total_in_id" class="col-form-label">Duplicata</label>
                    <div class="col">

                      <input v-model="caixa.duplicata_info" @input="formatInput_duplicata_info()" placeholder="digite"
                        id="total_in_id" type="text" class="form-control text-right">
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-3" id="desc_ID">
                  <div class="form-group">
                    <label for="total_in_id" class="col-form-label">Pix</label>
                    <div class="col">
                      <input v-model="caixa.pix_info" @input="formatInput_pix_info()" placeholder="digite"
                        id="total_in_id" type="text" class="form-control text-right">
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-3" id="desc_ID">
                  <div class="form-group">
                    <label for="total_in_id" class="col-form-label">PicPay</label>
                    <div class="col">
                      <input v-model="caixa.picpay_info" @input="formatInput_picpay_info()" placeholder="digite"
                        id="total_in_id" type="text" class="form-control text-right">
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-3" id="desc_ID">
                  <div class="form-group">
                    <label for="total_in_id" class="col-form-label">Transferencia</label>
                    <div class="col">
                      <input v-model="caixa.transferencia_info" @input="formatInput_transferencia_info()"
                        placeholder="digite" id="total_in_id" type="text" class="form-control text-right">
                      <div>
                        <b-form-datepicker disabled hidden  v-model="caixa.data_fecha" id="data"
                          placeholder="selecionar Data"></b-form-datepicker>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-row form-group" role="group" id="espaco">

                <!-- vou colocar algo aqui  -->

              </div>
            </b-card-body>
          </b-collapse>
          <!-- Menus de Impostos-->


          <!--- ------------ -->
        </b-card>
        <!-- engloba  todos-->
      </form>
      <!-- form engloba todos-->
    </div>
    <!--Engloba todos -->
    <div class="card-body" id="top">
      <div class="col-md-12 mt-10">
        <button @click="setCaixa_fechar()" class="btn btn-secondary" id="butt">
          Fechar caixa
        </button>
        <button @click="setFechar()" class="btn btn-secondary" id="butt">
          Cancelar
        </button>
        <button @click="coverte_para_float_valor_input()" class="btn btn-secondary" id="butt">
          Imprimir Relatorio
        </button>
      </div>
    </div>
  </div>
</template>


<style scoped>
/* .col{
  padding: 10px;
} */
#butt {
  padding: 10px;
  margin-left: 2%;
}

#top {
  margin-top: 50px;
}
</style>