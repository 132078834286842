<template>

<div class="custom-file">
    {{ diretorio }}
  <input  type="file" class="custom-file-input" id="customFile" @change="handleFileChange($event)">
  <label class="custom-file-label" for="customFile">{{ diretorio || 'Selecionar arquivo' }}</label>
</div>
</template>

<script>
export default {
   name:'upload',
   data() {
    return {
        diretorio: ''
    }
   },

   methods:{
    handleFileChange(event) {
      // Atualiza a variável 'diretorio' com o nome do arquivo selecionado

      this.diretorio = event.target.files[0].name;

      console.log(this.diretorio)
    },
    voltar(){

    }
   }
}
</script>

<style>

</style>