<script>
/**
 * Widget Component
 */
export default {
  props:['total_vendas', 'sangria'
      ,'suprimento', 'tot_caixa' , 'saldo_inicial', 'cancelamentos'],
  data() {
    return {
      statData: [
        {
          title: "Saldo Inicial",
          icon: "ri-store-2-line",
          value: '0,00',
          subvalue: ""
        },
        {
          title: "Vendas",
          icon: "ri-store-2-line",
          value: '0,00',
          subvalue: ""
        },
        {
          title: "Cancelamentos",
          icon: "ri-briefcase-4-line",
          value: "0,00",
          subvalue: ""
        },
        {
          title: "Sangrias",
          icon: "ri-stack-line",
          value: "0,00",
          subvalue: ""
        },
        {
          title: "Suprimentos",
          icon: "ri-store-2-line",
          value: "0,00",
          subvalue: ""
        },
        {
          title: "Total em Caixa",
          icon: "ri-store-2-line",
          value: "0,00",
          subvalue: ""
        },
      ]
    };

  },
  watch: {

    saldo_inicial(NovoSaldoinicial){
      this.statData[0].value =  NovoSaldoinicial;
    },
    total_vendas(novoTotal_vendas){
      this.statData[1].value =  novoTotal_vendas;
    },
    cancelamentos(novoCancelamentos){
      this.statData[2].value =  novoCancelamentos;
    },
    sangria(novoSangria){
      this.statData[3].value =  novoSangria;
    },
    suprimento(novoSuprimento){
      this.statData[4].value =  novoSuprimento;
    },
    tot_caixa(novoTot_caixa){
      this.statData[5].value =  novoTot_caixa;
    }
  }
};
</script>

<template>
  <div class="row">
    <div class="col-md-4" v-for="(data, index) in statData" :key="index">
      <div class="card">
        <div class="card-body">
          <div class="media">
            <div class="media-body overflow-hidden">
              <p class="text-truncate font-size-14 mb-2" >{{data.title}}</p>
              <h4 class="mb-0" :class="{ 'text-yellow': data.title === 'Sangrias' , 'text-red': data.title === 'Cancelamentos', 'text-green': data.title === 'Suprimentos' }">{{data.value}}</h4>
            </div>
            <div class="text-primary">
              <i :class="`${data.icon} font-size-24`"></i>
            </div>
          </div>
        </div>

        <div class="card-body border-top py-3">
          <div class="text-truncate">
            <span class="badge badge-soft-success font-size-11">
              <i class="mdi mdi-menu-up"></i>
              {{data.subvalue}}
            </span>
            <!-- <span class="text-muted ml-2">-Informação</span> -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- end row -->
</template>


<style scoped>

.text-yellow {
  color: yellow;
}

.text-red {
  color: red;
}
.text-green{
  color: green;
}
</style>
