<!-- mechedo aqui agora -->
<script>
import appConfig from "@/app.config";
import { http } from '@/helpers/easyindustriaapi/config';
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import List from "./list";
import Insert from "./insert";
import Edit from "./edit";
import info from "./tela_info_abertura.vue"
import Sangria from "./sangria.vue"
import Suprimento from "./suprimento.vue"
import upload from "./upload.vue"


export default {
  page: {
    title: 'Controle de Caixa',
    meta: [{ name: 'description', content: appConfig.description }],
  },
  components: { Layout, PageHeader, List, Edit, info, Insert, Sangria, Suprimento, upload },
  data() {
    return {
      current_caixa: {},
      movi_caixas: [

      ],
      movi_caixas2: [

      ],

      inserindo: false,
      editando: false,
      informacao: false,
      sangria: false,
      suprimento:false,
      showModal: false,
      titleBody: 'Controle de Caixa',
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Parâmetros",
          href: "/",
          active: true,
        },
        {
          text: "Controle de Caixa",
          href: "/parametros/controle_de_caixa",
          active: true,
        }
      ],
       // utilizando para abir o modal de sangria;
      abrir: false,
      // utilizado para abri o modal de suprimento
      abrir2:false,
      upload_verifica: false,
      espera_get:false
    }
  },
  computed: {
    isHide() {
      //!this.inserindo && 
      return (!this.editando && !this.informacao && !this.inserindo && !this.sangria && !this.suprimento && !this.upload_verifica);
    },
  },
  created() {
    this.currentUser = JSON.parse(localStorage.getItem('user'));
    this.currentEmpresa = JSON.parse(localStorage.getItem('currentEmpresa'));
  
  },
  mounted() {
    //console.log(this.currentEmpresa);
    
    this.getData();
    this.espera_get = true;
  },
  methods: {
    makeToast(variant = null, message = 'Error generic') {
      this.counter++;
      this.$bvToast.toast(message, {
        title: `Notificação`,
        toaster: 'b-toaster-bottom-right',
        variant: variant,
        solid: true,
        appendToast: true
      });
    },
    changeHomeTitle(newTitle) {
      this.titleBody = newTitle;
    },
    back() {
      this.espera_get=true
      this.showModal = false;
      this.inserindo = false;
      this.editando = false;
      this.sangria=false;
      this.suprimento=false;
      this.informacao = false;
      this.upload_verifica= false
      // utilizando para abir o modal de sangria;
      this.abrir = false;
      this.abrir2 = false;
      this.getData();

    },
    onLoader() {
      if (document.getElementById("preloader").style.display === "none") {
        document.getElementById("preloader").style.display = "block";
        document.getElementById("status").style.display = "block";
      }
    },
    offLoader() {
      if (document.getElementById("preloader").style.display === "block") {
        setTimeout(function () {
          document.getElementById("preloader").style.display = "none";
          document.getElementById("status").style.display = "none";
        }, 2500);
      }
    },
    async getData() {
      this.onLoader();

      try {
        let response = await http.get('/movimento-caixa?empresa_id=' + this.currentEmpresa.id);

        if (response.status === 200) {
          this.movi_caixas = response.data;
          this.movi_caixas2 = response.data;
          //console.log(this.movi_caixas)
          this.offLoader();
        }
      } catch (error) {
        let messageErro = error.response.data;

        switch (error.response.status) {
          case 406:
            this.makeToast('danger', 'Erro 406: ' + (messageErro.tipo) ? messageErro.tiponome : messageErro.tpag);
            this.offLoader();
            break;
          case 404:
            this.makeToast('danger', 'Erro 404: endpoint não encontrado ou servidor fora do ar');
            this.offLoader();
            break;

          default:
            this.makeToast('danger', error.message);
            this.offLoader();
            break;
        }
      }
    },
    async getData1() {
      this.onLoader();

      try {
        let response = await http.get('/movimento-caixa?empresa_id=' + this.currentEmpresa.id);

        if (response.status === 200) {
          this.movi_caixas = response.data;
          this.movi_caixas2 = response.data;
         // console.log(this.movi_caixas)
          this.offLoader();
        }
      } catch (error) {
        let messageErro = error.response.data;

        switch (error.response.status) {
          case 406:
            this.makeToast('danger', 'Erro 406: ' + (messageErro.tipo) ? messageErro.tiponome : messageErro.tpag);
            this.offLoader();
            break;
          case 404:
            this.makeToast('danger', 'Erro 404: endpoint não encontrado ou servidor fora do ar');
            this.offLoader();
            break;

          default:
            this.makeToast('danger', error.message);
            this.offLoader();
            break;
        }
      }
    },
    informando() {
      this.showModal = true;
      this.informacao = !this.informacao;
    },
    upload_veri(){
      this.upload_verifica = !this.upload_verifica
    },
    // insert () {
    //   // não vou usar aqui mais;
    //   // vou usar no do fechar;
    //   this.inserindo = !this.inserindo;
    // },
    edit(caixa) {
      // console.log(caixa);
      this.current_caixa = caixa;
      this.editando = !this.editando;
    },
    doFechar(caixa) {
      
      this.current_caixa = caixa;
      this.inserindo = !this.inserindo
    },
    // para o modal de sangria 
    doSangria(caixa){
      this.abrir = true
      //console.log(this.abrir)
      this.current_caixa = caixa;
      this.sangria = !this.sangria
     // console.log(this.current_caixa)
    },
    // utlizado para o modal de suprimento
    doSuprimento(caixa){
      this.abrir2 = true
      this.current_caixa = caixa;
      this.suprimento = !this.suprimento 
      // alert(caixa)
    },
    cancelar() {
      this.showModal = false;
      this.inserindo = false;
      this.sangria= false
      this.editando = false;
      this.informacao = false;
      this.suprimento= false;
      this.abrir=false;
      this.abrir2=false;
    },
    async doPost(caixa_abertura) {

      caixa_abertura.empresa_id = this.currentEmpresa.id;
      caixa_abertura.user_id = this.currentUser.id;
      caixa_abertura.user_id_abre = this.currentUser.id;
     // console.log(caixa_abertura)
      let response = await http.post('/movimento-caixa?empresa_id=' + this.currentEmpresa.id, caixa_abertura)
        .catch((error) => {
          this.makeToast('danger', error.response);
          this.offLoader();
        });

      if (response.status === 200) {
        this.getData();
        this.back();
        this.makeToast('success', 'Caixa Aberto');
      }
    },
    async caixa_fechar(fechamento_caixa) {

      this.espera_get=false;
     // console.log(fechamento_caixa)
      this.onLoader();
      
      fechamento_caixa.user_id = this.currentUser.id;
      let response = await http.put('/movimento-caixa/' + fechamento_caixa.id + '/fechar', fechamento_caixa)
        .catch((error) => {
          this.offLoader();
          this.makeToast('danger', error.response);
        });

      if (response.status === 200) {
        this.getData();
        this.back();
        this.makeToast('success', 'Registro alterado');
      }
    },
    async doPut(caixa) {
      this.onLoader();
      caixa.user_id = this.currentUser.id;
      let response = await http.put('/movimento-caixa/' + caixa.id + '?empresa_id=' + this.currentEmpresa.id, caixa)
        .catch((error) => {
          this.offLoader();
          this.makeToast('danger', error.response);
        });

      if (response.status === 200) {
        this.getData();
        this.back();
        this.makeToast('success', 'Registro alterado');
      }
    },
    async doDelete(historico) {
      this.onLoader();
      historico.empresa_id = this.currentEmpresa.id;
      let response = await http.delete('/historicopadrao/' + historico.id + '?empresa_id=' + this.currentEmpresa.id, historico)
        .catch((error) => {
          this.makeToast('danger', error.response);
          this.offLoader();
        });

      if (response.status === 200) {
        this.getData();
        this.back();
        this.makeToast('warning', 'Registro excluído');
      }
    },
  },
}
</script>

<template>
  <Layout>
    <PageHeader :title="titleBody" :items="items" />
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <!-- Start Bar Buttons -->
          <div class="card-body">
            <div class="row">
              <div class="col-sm-12 col-md-6"></div>
              <div class="col-sm-12 col-md-6 text-md-right">
                <button @click="informando()" v-if="isHide" type="button" class="btn btn-success">+ Abrir Caixa</button>
                <button @click="back()" v-if="!isHide" class="btn btn-secondary">Voltar</button>
                <button @click="upload_veri()" v-if="isHide" class="btn btn-secondary ml-2">Importar</button>
              </div>
            </div>
          </div>
          <!-- End Bar Buttons -->
          
          <div class="card-body" v-if="!isHide">
            <!-- estou usando para abrir caixa -->

            <upload  v-if="upload_verifica" />
            <info v-if="informacao" :empresa="currentEmpresa" @newTitle="changeHomeTitle" @doPost="doPost"
              :showModal="showModal" @back="back" />
              <!-- quanod clica sangira no tempplate list, traz o caixa de acordo com index , 
              para funçõa do sangria que ativa a variavel sangria para verdadeiro -->
              <Sangria v-if="sangria" @newTitle="changeHomeTitle" :abrir="abrir"  :oldCaixa="current_caixa" @back="back"
              :currentUser="currentUser" :currentEmpresa="currentEmpresa" /> 
              <Suprimento v-if="suprimento" @newTitle="changeHomeTitle" :abrir2="abrir2" :oldCaixa="current_caixa" @back="back" 
              :currentUser="currentUser" :currentEmpresa="currentEmpresa"  @getData="getData"  /> 
            <!-- estou usando para fechamento de caixa -->
            <Insert v-if="inserindo" :empresa="currentEmpresa" @newTitle="changeHomeTitle" @cancelar="cancelar"
              @caixa_fechar="caixa_fechar" :oldCaixa="current_caixa" />
            <Edit v-if="editando" :empresa="currentEmpresa" :oldCaixa="current_caixa" @newTitle="changeHomeTitle"
              @doPut="doPut" />
          </div>
          <List v-if="isHide" :currentEmpresa="currentEmpresa" :user="currentUser" :listmovi_caixas="movi_caixas" :caixas2="movi_caixas2" :hide="isHide"
            @newTitle="changeHomeTitle" @edit="edit" @doDelete="doDelete" @doFechar="doFechar" @doSangria="doSangria" @doSuprimento="doSuprimento" />
        </div>
      </div>
    </div>
  </Layout>
</template>