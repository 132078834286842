<script>
import jsPDF from 'jspdf';
import 'jspdf-autotable';

import widget from "./widget2.vue"
export default {
  props: {
    caixa: { type: Object },
  },
  components: {
    widget
  },
  data() {
    return {
      caixa_objeto: {}
    }
  },
  mounted() {
    this.caixa_objeto = this.caixa
    console.log(this.caixa_objeto);
    this.converterParaReal2()
  },
  methods: {
    gerarRelatorio() {
      const doc = new jsPDF();
      // Preencha as variáveis no relatório
      const relatorioText = `
        Cartão: ${this.caixa_objeto.cartao}
        Prazo: ${this.caixa_objeto.prazo}
        Duplicata: ${this.caixa_objeto.duplicata}
        Duplicata Info: ${this.caixa_objeto.duplicata_info}
        Cheque: ${this.caixa_objeto.cheque}
        Desconto: ${this.caixa_objeto.desconto}
        Pago: ${this.caixa_objeto.pago}
        Troco: ${this.caixa_objeto.troco}
        Sangria: ${this.caixa_objeto.sangria}
        Suprimento: ${this.caixa_objeto.suprimento}
        Total Geral: ${this.caixa_objeto.total_geral}
        Aberto: ${this.caixa_objeto.aberto}
        Cancelamentos: ${this.caixa_objeto.cancelamentos}
        Cartão Info: ${this.caixa_objeto.cartao_info}
        Prazo Info: ${this.caixa_objeto.prazo_info}
        Cheque Info: ${this.caixa_objeto.cheque_info}
        Dinheiro: ${this.caixa_objeto.dinheiro}
        Dinheiro Info: ${this.caixa_objeto.dinheiro_info}
        Pix: ${this.caixa_objeto.pix}
        Pix Info: ${this.caixa_objeto.pix_info}
        Picpay: ${this.caixa_objeto.picpay}
        Picpay Info: ${this.caixa_objeto.picpay_info}
        Transferência: ${this.caixa_objeto.transferencia}
        Transferência Info: ${this.caixa_objeto.transferencia_info}
        Cartão Débito: ${this.caixa_objeto.cartao_debito}
        Cartão Débito Info: ${this.caixa_objeto.cartao_debito_info}
      `;

      doc.text(relatorioText, 10, 10);
      doc.save('relatorio.pdf');
    },
    gerarRelatorio2() {
      // Dados a serem utilizados na tabela
      // const dados = [
      //   {
      //     descricao: "Cartão",
      //     Valor: 123,
      //     Info: 123,
      //   },
      //   // Adicione outras linhas de dados conforme necessário
      //   // { descricao: "...", valor: ..., info: ... }
      // ];

      // Configurações do documento PDF
      const doc = new jsPDF();
      const titulo = "Relatório Financeiro";
      const header = ["Descrição", "Valor", "Info"];
      const dados2 = ["Dinheiro", `${this.caixa_objeto.dinheiro}`, `${this.caixa_objeto.dinheiro_info}`];
      const dados3 = ["Pix", `${this.caixa_objeto.pix}`, `${this.caixa_objeto.pix_info}`];
      const dados4 = ["Credito", `${this.caixa_objeto.cartao}`, `${this.caixa_objeto.cartao_info}`];
      const dados5 = ["Debito", `${this.caixa_objeto.cartao_debito}`, `${this.caixa_objeto.cartao_debito_info}`];
      const dados6 = ["Duplicata", `${this.caixa_objeto.duplicata}`, `${this.caixa_objeto.duplicata_info}`];
      const dados7 = ["Cheque", `${this.caixa_objeto.cheque}`, `${this.caixa_objeto.cheque_info}`];
      const dados8 = ["Desconto", `${this.caixa_objeto.desconto}`, `${this.caixa_objeto.desconto}`];
      const dados9 = ["Picpay", `${this.caixa_objeto.picpay}`, `${this.caixa_objeto.picpay_info}`];
      const dados10 = ["Transferência", `${this.caixa_objeto.transferencia}`, `${this.caixa_objeto.transferencia_info}`];
      const dados11 = ["Cancelamentos", `${this.caixa_objeto.cancelamentos}`, `${this.caixa_objeto.cancelamentos}`];
      const footer = ["Total Geral", this.caixa_objeto.total_geral, ""];
      const footer2 = ["Suprimento", this.caixa_objeto.suprimento, ""];
      const footer3 = ["Sangria", this.caixa_objeto.sangria, ""];
      const footer4 = ["Vendas", this.caixa_objeto.total_cupom, ""];
      const footer5 = ["Saldo Inicial", this.caixa_objeto.saldo_inicial, ""];

      // Adicionar o header e o foote
      doc.text(titulo, 80, 10,);
      doc.autoTable({
        head: [header],
        body: 
        [     
          dados2,dados3,
          dados4,dados5,
          dados6,dados7,
          dados8,dados9,
          dados10,dados11,
       ],
        foot: [footer5,footer2,footer3,footer4,footer],
      });

      // Salvar o documento
      doc.save("relatorio.pdf");
    },

    converterParaReal2() {
      const valor = parseFloat(this.caixa_objeto.total_geral); // Seu valor numérico
      const valor2 = parseFloat(this.caixa_objeto.sangria); // Seu valor numérico
      const valor3 = parseFloat(this.caixa_objeto.suprimento); // Seu valor numérico
      const valor4 = parseFloat(this.caixa_objeto.total_cupom); // Seu valor numérico
      const formatoMoeda = {
        style: "currency",
        currency: "BRL"
      };
      const formatoMoeda2 = {
        style: "currency",
        currency: "BRL"
      };
      // so funciona quando o numero é enviado de uma vez
      const valorFormatado = valor.toLocaleString("pt-BR", formatoMoeda);
      const valorFormatado2 = valor2.toLocaleString("pt-BR", formatoMoeda2);
      const valorFormatado3 = valor3.toLocaleString("pt-BR", formatoMoeda2);
      const valorFormatado4 = valor4.toLocaleString("pt-BR", formatoMoeda2);
      ///console.log(valorFormatado); // Saída: R$ 1.234,56
      this.caixa_objeto.total_geral = valorFormatado.replace("R$"," ")
      this.caixa_objeto.sangria = valorFormatado2.replace("R$"," ")
      this.caixa_objeto.suprimento = valorFormatado3.replace("R$"," ")
      this.caixa_objeto.total_cupom = valorFormatado4.replace("R$"," ")

    },
  }
}
</script>

<template>
  <!-- <div class="caixa">
    <div class="card col-sm-12 col-md-6 " >
      <div class="col">
        <p>ID: {{ caixa.id }}</p>
        <p>Nome: {{ caixa.descric }}</p>
        <p>Usuário: {{ caixa.user_id }}</p>
        <p>Ativo: <b-badge data-name="status" class="field-status"
            v-bind:variant="(caixa.status) ? 'success' : 'danger'">{{
              (caixa.status) ? 'Sim' : 'Não' }}</b-badge></p>
        <p>Criado: <b-badge data-name="created_at" class="field-created_at" variant="secondary">{{ caixa.created_at
        }}</b-badge></p>
        <p>Última alteração: <b-badge data-name="updated_at" class="field-updated_at" variant="secondary">{{
          caixa.updated_at }}</b-badge></p>
      </div>
      <div class="card col-sm-12 col-md-6">
      <div class="col">
        <p>ID: {{ caixa.id }}</p>
        <p>Nome: {{ caixa.descric }}</p>
        <p>Usuário: {{ caixa.user_id }}</p>
        <p>Ativo: <b-badge data-name="status" class="field-status"
            v-bind:variant="(caixa.status) ? 'success' : 'danger'">{{
              (caixa.status) ? 'Sim' : 'Não' }}</b-badge></p>
        <p>Criado: <b-badge data-name="created_at" class="field-created_at" variant="secondary">{{ caixa.created_at
        }}</b-badge></p>
        <p>Última alteração: <b-badge data-name="updated_at" class="field-updated_at" variant="secondary">{{
          caixa.updated_at }}</b-badge></p>
      </div>
    </div>
    </div>
    
  </div> -->

  <div class="caixa">
    <div class="card">
      <div class="card-body">
        <widget :total_vendas="formatterFloatBR(caixa_objeto.total_cupom)" :sangria="caixa_objeto.sangria"
          :suprimento="caixa_objeto.suprimento" :tot_caixa="caixa_objeto.total_geral" 
          :saldo_inicial="caixa_objeto.saldo_inicial"  :cancelamentos="caixa_objeto.cancelamentos"/>
        <!-- <div class="form-row form-group" role="group">
          <widget />
          <div class="col-sm-12 col-md-2">
            <div class="form-group">
              <label for="centro_resultado_id" class="col-form-label">Cod Caixa</label>
              <div class="col">
                <input disabled v-model="caixa_objeto.caixa_id" class="form-control text-left" type="text"
                  placeholder="Gerado pelo sistema">
                  
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-md-3">
            <div class="form-group">
              <label for="centro_resultado_id" class="col-form-label">Total Vendas</label>
              <div class="col">
                <input disabled v-model="caixa_objeto.total_geral" class="form-control text-right" type="text" placeholder="Gerado pelo sistema">
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-md-2">
            <div class="form-group">
              <label for="centro_resultado_id" class="col-form-label">Sangria</label>
              <div class="col">
                <input disabled v-model="caixa_objeto.sangria" class="form-control text-right" type="text" placeholder="Gerado pelo sistema">
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-md-2">
            <div class="form-group">
              <label for="centro_resultado_id" class="col-form-label">Suprimento</label>
              <div class="col">
                <input disabled v-model="caixa_objeto.suprimento" class="form-control text-right" type="text"
                  placeholder="Gerado pelo sistema">
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-md-3">
            <div class="form-group">
              <label for="centro_resultado_id" class="col-form-label">Total em Caixa</label>
              <div class="col">
                <input disabled v-model="caixa_objeto.total_geral" class="form-control text-right" type="text"
                  placeholder="Gerado pelo sistema">
              </div>
            </div>
          </div>
        </div> -->
      </div>
    </div>
    <div class="card">
      <div class="row">
        <div class="col-md-6">
          <Label for="Sitema" class="col-md-12 text-center">Valor do Sistema</Label>
          <hr>
          <div class="card">
            <div class="row">
              <div class="col-md-6 text-right">Dinheiro:</div>
              <div class="col-md-6 ">
                <b-badge data-name="credito" class="field-status"
                  v-bind:variant="(caixa_objeto.dinheiro) ? 'success' : 'danger'">{{
                    (caixa_objeto.dinheiro) ? caixa_objeto.dinheiro : 0 }}</b-badge>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 text-right">Pix:</div>
              <div class="col-md-6 ">
                <b-badge data-name="credito" class="field-status"
                  v-bind:variant="(caixa_objeto.pix) ? 'success' : 'danger'">{{
                    (caixa_objeto.pix) ? caixa_objeto.pix : 0 }}</b-badge>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 text-right">Cartão de Credito:</div>
              <div class="col-md-6 ">
                <b-badge data-name="credito" class="field-status"
                  v-bind:variant="(caixa_objeto.cartao) ? 'success' : 'danger'">{{
                    (caixa_objeto.cartao) ? caixa_objeto.cartao : 0 }}</b-badge>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 text-right">Cartão de Debito:</div>
              <div class="col-md-6 ">
                <b-badge data-name="credito" class="field-status"
                  v-bind:variant="(caixa_objeto.cartao_debito) ? 'success' : 'danger'">{{
                    (caixa_objeto.cartao_debito) ? caixa_objeto.cartao_debito : 0 }}</b-badge>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 text-right">Duplicata:</div>
              <div class="col-md-6 ">
                <b-badge data-name="credito" class="field-status"
                  v-bind:variant="(caixa_objeto.duplicata) ? 'success' : 'danger'">{{
                    (caixa_objeto.duplicata) ? caixa_objeto.duplicata : 0 }}</b-badge>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 text-right">Cheque:</div>
              <div class="col-md-6 ">
                <b-badge data-name="credito" class="field-status"
                  v-bind:variant="(caixa_objeto.cheque) ? 'success' : 'danger'">{{
                    (caixa_objeto.cheque) ? caixa_objeto.cheque : 0 }}</b-badge>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 text-right">Desconto:</div>
              <div class="col-md-6 ">
                <b-badge data-name="credito" class="field-status"
                  v-bind:variant="(caixa_objeto.desconto) ? 'success' : 'danger'">{{
                    (caixa_objeto.desconto) ? caixa_objeto.desconto : 0 }}</b-badge>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 text-right">Picpay:</div>
              <div class="col-md-6 ">
                <b-badge data-name="credito" class="field-status"
                  v-bind:variant="(caixa_objeto.picpay) ? 'success' : 'danger'">{{
                    (caixa_objeto.picpay) ? caixa_objeto.picpay : 0 }}</b-badge>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 text-right">Transferencia:</div>
              <div class="col-md-6 ">
                <b-badge data-name="credito" class="field-status"
                  v-bind:variant="(caixa_objeto.transferencia) ? 'success' : 'danger'">{{
                    (caixa_objeto.transferencia) ? caixa_objeto.transferencia : 0 }}</b-badge>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 text-right">Cancelamentos:</div>
              <div class="col-md-6 ">
                <b-badge data-name="credito" class="field-status"
                  v-bind:variant="(caixa_objeto.cancelamentos) ? 'success' : 'danger'">{{
                    (caixa_objeto.cancelamentos) ? caixa_objeto.cancelamentos : 0 }}</b-badge>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <Label for="Sitema text-center" class="col-md-12 text-center">Valores Informados</Label>
          <hr>
          <div class="card">
            <div class="row">
              <div class="col-md-6 text-right">Dinheiro:</div>
              <div class="col-md-6 ">
                <b-badge data-name="credito" class="field-status"
                  v-bind:variant="(caixa_objeto.dinheiro_info) ? 'success' : 'danger'">{{
                    (caixa_objeto.dinheiro) ? caixa_objeto.dinheiro_info : 0 }}</b-badge>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 text-right">Pix:</div>
              <div class="col-md-6 ">
                <b-badge data-name="credito" class="field-status"
                  v-bind:variant="(caixa_objeto.pix_info) ? 'success' : 'danger'">{{
                    (caixa_objeto.pix_info) ? caixa_objeto.pix_info : 0 }}</b-badge>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 text-right">Cartão de Credito:</div>
              <div class="col-md-6 ">
                <b-badge data-name="credito" class="field-status"
                  v-bind:variant="(caixa_objeto.cartao_info) ? 'success' : 'danger'">{{
                    (caixa_objeto.cartao_info) ? caixa_objeto.cartao_info : 0 }}</b-badge>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 text-right">Cartão de Debito:</div>
              <div class="col-md-6 ">
                <b-badge data-name="credito" class="field-status"
                  v-bind:variant="(caixa_objeto.cartao_debito_info) ? 'success' : 'danger'">{{
                    (caixa_objeto.cartao_debito_info) ? caixa_objeto.cartao_debito_info : 0 }}</b-badge>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 text-right">Duplicata:</div>
              <div class="col-md-6 ">
                <b-badge data-name="credito" class="field-status"
                  v-bind:variant="(caixa_objeto.duplicata_info) ? 'success' : 'danger'">{{
                    (caixa_objeto.duplicata_info) ? caixa_objeto.duplicata_info : 0 }}</b-badge>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 text-right">Cheque:</div>
              <div class="col-md-6 ">
                <b-badge data-name="credito" class="field-status"
                  v-bind:variant="(caixa_objeto.cheque_info) ? 'success' : 'danger'">{{
                    (caixa_objeto.cheque_info) ? caixa_objeto.cheque_info : 0 }}</b-badge>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 text-right">Desconto:</div>
              <div class="col-md-6 ">
                <b-badge data-name="credito" class="field-status"
                  v-bind:variant="(caixa_objeto.desconto_info) ? 'success' : 'danger'">{{
                    (caixa_objeto.desconto_info) ? caixa_objeto.desconto_info : 0 }}</b-badge>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 text-right">Picpay:</div>
              <div class="col-md-6 ">
                <b-badge data-name="credito" class="field-status"
                  v-bind:variant="(caixa_objeto.picpay_info) ? 'success' : 'danger'">{{
                    (caixa_objeto.picpay_info) ? caixa_objeto.picpay_info : 0 }}</b-badge>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 text-right">Transferencia:</div>
              <div class="col-md-6 ">
                <b-badge data-name="credito" class="field-status"
                  v-bind:variant="(caixa_objeto.transferencia_info) ? 'success' : 'danger'">{{
                    (caixa_objeto.transferencia_info) ? caixa_objeto.transferencia_info : 0 }}</b-badge>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 text-right">Cancelamentos:</div>
              <div class="col-md-6 ">
                <b-badge data-name="credito" class="field-status"
                  v-bind:variant="(caixa_objeto.cancelamentos) ? 'success' : 'danger'">{{
                    (caixa_objeto.cancelamentos) ? caixa_objeto.cancelamentos : 0 }}</b-badge>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body d-flex justify-content-center align-items-center" id="top">
        <div class="col-md-12 mt-12 text-center"> <!-- Adicionado "text-center" aqui -->
          <button class="btn btn-secondary" id="butt" @click="gerarRelatorio2()">
            Imprimir Relatorio
          </button>
        </div>
      </div>
    </div>

  </div>
</template>