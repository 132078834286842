<script>
export default {
  props: {
    empresa: { type: Object, required: true },
    oldCaixa: { type: Object, required: true },
  },
  data() {
    return {
      titleBody: 'Alterar Caixa',
      caixa: {

      },
      empresa_nome: null,

    }
  },
  created() { },
  computed: {
    dataAtual() {
      return new Date().toISOString().substr(0, 10); // Obter a data atual no formato "yyyy-mm-dd"
    }
  },
  mounted() {
    this.$emit('newTitle', this.titleBody);
    this.caixa = this.oldCaixa;
    this.empresa_nome = this.empresa.fantasia;
    this.caixa.data_abre = this.dataAtual
    console.log(this.caixa);
  },

  methods: {
    setPut() {
      this.$emit('doPut', this.caixa);
    },
  },
}
</script>

<template>
  <div class="card">

    <div class="card-body">

      <form id="form-centro-resultado" role="form" class="form-horizontal">
        <div class="form-row form-group" role="group">
          <div class="col-sm-12 col-md-4">
            <div class="form-group">
              <label for="centro_resultado_id" class="col-form-label">Empresa</label>
              <div class="col">
                <input v-model="empresa_nome" disabled class="form-control text-left" type="text"
                  placeholder="Gerado pelo sistema">
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-md-2">
            <div class="form-group">
              <label for="Periodo_id" class="col-form-label">Periodo</label>
              <div class="col">
                <div>
                  <b-form-datepicker disabled v-model="caixa.data_abre" id="data"
                    placeholder="selecionar Data"></b-form-datepicker>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-md-3">
            <div class="form-group">
              <label for="centro_resultado_id" class="col-form-label">Saldo inicial</label>
              <div class="col">
                <input v-model="caixa.saldo_inicial" class="form-control text-right" type="text"
                  placeholder="Gerado pelo sistema">
              </div>
            </div>
          </div>

          <div class="col-sm-12 col-md-3">
            <div class="form-group">
              <label for="centro_resultado_id" class="col-form-label">Total em Caixa</label>
              <div class="col">
                <input v-model="caixa.total_geral" class="form-control text-right" type="text"
                  placeholder="Gerado pelo sistema">

              </div>
            </div>
          </div>
        </div>


      </form>
    </div>

    <div class="card-body" id="top">
      <div class="col-md-12 mt-10">
        <button class="btn btn-light" id="butt" @click="setPut()">
          Salvar
        </button>
      </div>
  </div>
</div></template>