<!-- mechedo aqui agora -->
<script>
import { http } from '@/helpers/easyindustriaapi/config';
import stat from './widget.vue';
import Multiselect from "vue-multiselect";
import Vue from 'vue';
import VueCurrencyFilter from 'vue-currency-filter';
Vue.use(VueCurrencyFilter, {
  symbol: 'R$',
  thousandsSeparator: '.',
  fractionCount: 2,
  fractionSeparator: ',',
  symbolPosition: 'front',
  symbolSpacing: true,
});

export default {
  page: {
    title: 'Sangria',
    //meta: [{ name: 'description', content: appConfig.description }],
  },


  props: {
    oldCaixa: { type: Object },
    currentUser: { type: Object },
    abrir2: { type: Boolean },
    currentEmpresa: { type: Object }
  },
  components: {
    Multiselect,
    stat,
    // Number
  },
  data() {
    return {
      titleBody: "Suprimento",
      items_suprimento: [],
      suprimento: {
        nome: null,
        caixa_id: null,
        tipo_pagamento_id: null,
        valor: 0,
        movimento_caixa_id: null,
        user_id: null,
        tipo_pagamento: {
          id: -1,
          tiponome: 'selecione',
          tpag: null
        }
      },
      suprimentos: [],
      // adicionado um atributo exluir para cada objeto do vetor items_suprometnos
      vetor_com_exluir: [],
      
      select_tipo: null,
      open: false,
      valorConvertido: '',
      numero: 0,
      timeout: null,
      valor_suprimento: null,
      pagamento_suprimento: null,
      valor: 0,
      originalMoneyValue: '', // Valor original do input
      moneyValue: 'R$ 0,00', // Valor formatado,
      valor_digitado: null,
      inputValue: '',
      pagamentos: [],
      empresa_id: null,
      soma: false,
      fields: [
        { label: "ID", key: "id", tdClass: 'text-right', thClass: 'text-center' },
        { label: "Nome", key: "nome", tdClass: 'text-left', thClass: 'text-center' },
        { label: "Tipo Pagamento", key: "tipo_pagamento_id", tdClass: 'text-left', thClass: 'text-center' },
        { label: "Valor", key: "valor", tdClass: 'text-right', thClass: 'text-center', formatter: "formatterCurrBR" },
        { label: "Criado", key: "data_criacao", tdClass: 'text-right', thClass: 'text-center', formatter: "formatDateTimeBR"},
        { label: "Ações", key: "excluir", tdClass: 'text-center', thClass: 'text-center' },
      ]
    }
  },
  watch: {
    originalMoneyValue(newValue) {
      this.formatMoneyValue(newValue);
    }
  },
  computed: {
    formattedMoneyValue() {
      let value = this.moneyValue;
      if (!value || value === null || value === '') {
        return 'R$ 0,00';
      }
      if (typeof value === 'string') {
        value = parseFloat(value);
      }
      function numberToReal(numero) {
        numero = numero.toFixed(2).split('.');
        numero[0] = 'R$ ' + numero[0].split(/(?=(?:...)*$)/).join('.');
        return numero.join(',');
      }
      return numberToReal(value);
    }
  },
  created() {
    this.empresa_id = this.currentEmpresa.id;
    this.suprimento.movimento_caixa_id = this.oldCaixa.id;
    this.total_caixa = this.oldCaixa.total_geral;
    this.open = this.abrir2;
    this.caixa_id = this.oldCaixa.id;
    // esse coverte o total_caixa;
    // mas talvez não irei usalo;
    this.suprimento.caixa_id = this.oldCaixa.id;
  },
  mounted() {
    this.get_suprimento();
    this.get_tipos_pagamento();
    this.$emit('newTitle', this.titleBody);
  },
  methods: {
    async get_tipos_pagamento() {
      let id = this.empresa_id;
      try {
        let response = await http.get('/tipopagamento?empresa_id=' + id);
        if (response.status === 200) {
          this.pagamentos = response.data;
        }
      } catch (error) {
        let messageErro = error.response.data;
        switch (error.response.status) {
          case 406:
            this.makeToast('danger', 'Erro 406: ' + (messageErro.tipo) ? messageErro.tiponome : messageErro.tpag);
            break;
          case 404:
            this.makeToast('danger', 'Erro 404: endpoint não encontrado ou servidor fora do ar');
            break;
          default:
            this.makeToast('danger', error.message);
            break;
        }
      }
    },
    formatMoneyValue(value) {
      if (!value || value === null || value === '') {
        this.moneyValue = 'R$ 0,00';
      } else if (typeof value === 'string') {
        value = parseFloat(value);
        this.moneyValue = this.numberToReal(value);
      }
    },
    numberToReal(numero) {
      numero = numero.toFixed(2).split('.');
      numero[0] = 'R$ ' + numero[0].split(/(?=(?:...)*$)/).join('.');
      return numero.join(',');
    },

    makeToast(variant = null, message = 'Error generic') {
      this.counter++;
      this.$bvToast.toast(message, {
        title: `Notificação`,
        toaster: 'b-toaster-bottom-right',
        variant: variant,
        solid: true,
        appendToast: true
      });
    },
    select_tipo_pagemnto(objeto) {
      this.pagamento_suprimento = objeto.tiponome;
      this.suprimento.tipo_pagamento_id = objeto.id;
      this.suprimento.tipo_pagamento = objeto;
    },
    // suprimento_para real 
    converterParaReal() {
      const valor = parseFloat(this.valor_suprimento); // Seu valor numérico
      const formatoMoeda = {
        style: "currency",
        currency: "BRL"
      };
      // so funciona quando o numero é enviado de uma vez
      const valorFormatado = valor.toLocaleString("pt-BR", formatoMoeda);
      this.valor_suprimento = valorFormatado.replace("R$", " ");
    },
    // usado para o total em caixa_ sem ser o input;
    converterParaReal2() {
      const valor = parseFloat(this.total_caixa); // Seu valor numérico
      const formatoMoeda = {
        style: "currency",
        currency: "BRL"
      };
      // so funciona quando o numero é enviado de uma vez
      const valorFormatado = valor.toLocaleString("pt-BR", formatoMoeda);
      this.total_caixa = valorFormatado.replace("R$", " ");
    },
    BRL(value) {
      const formatoMoeda = {
        style: "currency",
        currency: "BRL"
      };
      // so funciona quando o numero é enviado de uma vez
      const valorFormatado = value.toLocaleString("pt-BR", formatoMoeda);
      return valorFormatado.replace("R$", " ");
    },

    // ------------------------------------------------------
    // INICIO usado para o input do total caixa_suprimento
    converterParaReal_tot_caixa_input() {
      const valor = parseFloat(this.total_caixa_suprimento); // Seu valor numérico
      const formatoMoeda = {
        style: "currency",
        currency: "BRL"
      };
      // so funciona quando o numero é enviado de uma vez
      const valorFormatado = valor.toLocaleString("pt-BR", formatoMoeda);
      this.total_caixa_suprimento = valorFormatado.replace("R$", " ");
    },
    aguardarDigitacao_tot_caixa_input() {
      if (this.total_caixa_suprimento == null || isNaN(this.total_caixa_suprimento)) {
        this.total_caixa_suprimento = '';
      } else {
        clearTimeout(this.timeout); // Limpa o timeout anterior, se houver
        this.timeout = setTimeout(() => {
          this.executarAposDigitar_tot_caixa_input();
        }, 1000); // Defina o tempo de espera desejado (em milissegundos)
      }
    },
    executarAposDigitar_tot_caixa_input() {
      // Função que será executada após o término da digitação
      this.converterParaReal_tot_caixa_input();
      this.coverte_para_float_tot_caixa_input();
    },
    coverte_para_float_tot_caixa_input() {
      let stringValor = this.total_caixa_suprimento;
      let valorFlutuante = parseFloat(stringValor.replace("R$", " ").replace(/\./g, "").replace(",", "."));
      this.suprimento.total_caixa = valorFlutuante;
    },
    // ---------------  fim  tot_caixa_input--------------------------------
    aguardarDigitacao() {
      if (this.valor_suprimento == null || isNaN(this.valor_suprimento)) {
        this.valor_suprimento = '';
      } else {
        clearTimeout(this.timeout); // Limpa o timeout anterior, se houver
        this.timeout = setTimeout(() => {
          this.executarAposDigitar();
        }, 1000); // Defina o tempo de espera desejado (em milissegundos)
      }
    },
    // aguarda() {
    //   if (this.valor_suprimento == null || isNaN(this.valor_suprimento)) {
    //     this.valor_suprimento = '';
    //   } else {
    //     clearTimeout(this.timeout); // Limpa o timeout anterior, se houver
    //     this.timeout = setTimeout(() => {
    //       this.executarAposDigitar1();
    //     }, 1000); // Defina o tempo de espera desejado (em milissegundos)
    //   }
    // },
    executarAposDigitar1() {
      this.valor = this.valor_digitado;
    },
    executarAposDigitar() {
      // Função que será executada após o término da digitação
      this.converterParaReal();
      this.coverte_para_float();
    },
    // usando esse 
    formatInput_valor_supriemnto() {
      // Remova todos os caracteres não numéricos do valor de entrada
      let numericValue = this.valor_suprimento.replace(/\D/g, '');
      // Formate o valor numérico para a moeda desejada (por exemplo, BRL)
      this.valor_suprimento = parseFloat(numericValue / 100).toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      }).replace("R$", " ");

      this.coverte_para_float_valor_input();
    },
    coverte_para_float_valor_input() {
      let stringValor = this.valor_suprimento;
      let valorFlutuante = parseFloat(stringValor.replace("R$", " ").replace(/\./g, "").replace(",", "."));
      this.suprimento.valor = valorFlutuante;
    },
    removerupdate_att() {
      var newItems = this.vetor_com_exluir.map(function (item) {
        // Cria um novo objeto com os atributos desejados, excluindo 'update_at'
        return {
          id: item.id,
          nome: item.nome,
          tipo_pagamento_id: item.tipo_pagamento_id,
          valor: item.valor,
          data_criacao: item.created_at,
          excluir: item.excluir
        };
      });
      this.vetor_com_exluir = newItems;
    },

    // adiciono a coluna exluir no meu vetor para ser exibido na tela;
    add_excluir_coluna() {
      var itemsComExcluir = this.items_suprimento.map(function (item) {
        var tipoPagamentoString;
        switch (item.tipo_pagamento_id) {
          case 1:
            tipoPagamentoString = 'Dinheiro';
            break;
          case 2:
            tipoPagamentoString = 'Duplicata';
            break;
          case 3:
            tipoPagamentoString = 'Cheque';
            break;
          case 4:
            tipoPagamentoString = 'Cartão de Credito';
            break;
          case 5:
            tipoPagamentoString = 'Cartão de Debito';
            break;
          case 6:
            tipoPagamentoString = 'Cupom Devolução';
            break;
          case 7:
            tipoPagamentoString = 'transferencia';
            break;
          case 8:
            tipoPagamentoString = 'vale alimentacao';
            break;
          case 9:
            tipoPagamentoString = 'vale refeicao';
            break;
          case 10:
            tipoPagamentoString = 'vale presente';
            break;
          case 11:
            tipoPagamentoString = 'vale combustivel';
            break;
          case 12:
            tipoPagamentoString = 'sem pagamento';
            break;
          default:
            tipoPagamentoString = 'outros';
            break;
        }
        return { ...item, excluir: true, tipo_pagamento_id: tipoPagamentoString };
      });
      this.vetor_com_exluir = itemsComExcluir;
    },
    async get_atualizar_caixa() {
      this.soma = true;

      try {
        let response = await http.get('movimento-caixa/' + this.oldCaixa.id);
        if (response.status === 200) {
          this.caixa_atualizado = response.data;
          this.total_caixa = 0;
          this.total_caixa = this.caixa_atualizado.total_geral;
          this.soma = false;
        }
      } catch (error) {
        let messageErro = error.response.data;

        switch (error.response.status) {
          case 406:
            this.makeToast('danger', 'Erro 406: ' + (messageErro.tipo) ? messageErro.tiponome : messageErro.tpag);
            break;
          case 404:
            this.makeToast('danger', 'Erro 404: endpoint não encontrado ou servidor fora do ar');
            break;
          default:
            this.makeToast('danger', error.message);
            break;
        }
      }
    },
    verifica_saldo_tipo(suprimento) {
      if (suprimento.tipo_pagamento_id == 1 && suprimento.valor > this.oldCaixa.dinheiro ) {
        this.makeToast('danger', 'saldo de dinheiro insuficiente'); 
      } 
      if (suprimento.tipo_pagamento_id == 2 && suprimento.valor > this.oldCaixa.duplcata) {
        this.makeToast('danger', 'saldo de duplicata insuficiente');
      } 
       if (suprimento.tipo_pagamento_id == 3 && suprimento.valor > this.oldCaixa.cheque) {
        this.makeToast('danger', 'saldo de cheque insuficiente');
      } 
       if (suprimento.tipo_pagamento_id == 4 && suprimento.valor > this.oldCaixa.cartao) {
        this.makeToast('danger', 'saldo de cartao de credito insuficiente');
      } 
       if (suprimento.tipo_pagamento_id == 5 && suprimento.valor > this.oldCaixa.cartao_debito ) {
        this.makeToast('danger', 'saldo de cartao de debito insuficiente');
      }
    },
    async post_Suprimento() {
      this.soma = true;
      this.suprimento.user_id = this.currentUser.id;
      let suprimento = this.suprimento;
      //-------------------------------------------------------
      // USANDO PARA VEFICAR O TIPO DE SALDO DO CAIXA;
      // PARA PODER REALIZAR SUPRIMENTO
      //this.verifica_saldo_tipo(suprimento);
      // -------------------------------------------------------
      // falta apenas coocar o moviemto_caxa_id;
      this.total_caixa += this.suprimento.valor;
      try {
        let response = await http.post('/suprimento', suprimento)
          .catch((error) => {
            this.error(error.response);
            this.makeToast('danger', error.response);
          });
        if (response.status === 200) {
          this.ativa_card = true;
          this.makeToast('success', 'Registro incluído');
          //this.get_suprimento();
          // deopis de adiocnar tem que zerar o objeto suprimento,
          // pq fiica salvo o valor anterior , 
          // é isso permite que faça um post sem inlcuir os dados nos inputs;
          this.suprimento.tipo_pagamento_id = null;
          this.suprimento.tipo_pagamento_id = null;
          this.suprimento.tipo_pagamento.tiponome = 'selecione';
          this.suprimento.nome = null;
          this.suprimento.valor = 0;
          this.suprimento.total_caixa = null;
          this.select_tipo = null;
          this.valor_suprimento = null
          this.pagamento_suprimento = null
          this.get_suprimento()
          this.soma = false
        }
      } catch (error) {
        this.makeToast('danger', error.response);
      }
    },
    coverte_para_float() {
      let stringValor = this.valor_suprimento;
      let valorFlutuante = parseFloat(stringValor.replace("R$", " ").replace(/\./g, "").replace(",", "."));
      this.suprimento.valor = valorFlutuante
    },
    async delete_Suprimento(id) {
      // o primeiro id é um objeto , do vetor items_suprimento
      let response = await http.delete('/suprimento/' + id.id)
        .catch((error) => {
          this.makeToast('danger', error.response);
          this.offLoader();
        });
      if (response.status === 200) {
        this.makeToast('warning', 'Registro excluído');
        this.get_suprimento();
        this.get_atualizar_caixa();
      }
    },
    async get_suprimento() {
      try {
        let response = await http.get('/suprimento?movimento_caixa_id=' + this.oldCaixa.id);
        if (response.status === 200) {
          this.suprimentos = response.data;
          this.items_suprimento = this.suprimentos;
          // utilzei para vriar um copia do vetor items_suopriemntos para adicionar ao final 
          // de cada objeto um atribtuo exluir , para poder criar um botão utizando um componete 
          //vue-botestrap
          //e também mudar o tipo de pagamento para uma string , para ficar melhor de visualaizar
          this.add_excluir_coluna()
          //removeo o updaet_at, e renomei o created_at 
          this.removerupdate_att()
        }
      } catch (error) {
        let messageErro = error.response.data;

        switch (error.response.status) {
          case 406:
            this.makeToast('danger', 'Erro 406: ' + (messageErro.tipo) ? messageErro.tiponome : messageErro.tpag);
            break;
          case 404:
            this.makeToast('danger', 'Erro 404: endpoint não encontrado ou servidor fora do ar');
            break;
          default:
            this.makeToast('danger', error.message);
            break;
        }
      }
    },
    setTotalCaixa(valor) {
      alert(valor)
    },
    back() {
      this.$emit('back')
    }
  },
}
</script>
<template>
  <b-modal v-model="open" size="xl" title="Suprimento" hide-footer @hidden="back()">
    <div class="card">
      <div>
        <div class="col-md-12" v-if="!soma">
          <stat :item="suprimento" :tot_caixa="total_caixa"/>
        </div>
      </div>
      <b-table-simple>
        <!-- nem precisaria do totoal em caixa; -->
        <b-thead id="b-head">
          <b-tr>
            <b-th class="b-th">Código Caixa</b-th>
            <b-th class="b-th">Nome</b-th>
            <b-th class="b-th">Tipo pagamento</b-th>
            <b-th class="b-th">Valor</b-th>
            <b-th class="b-th">&nbsp;</b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr>
            <!-- nem precisaria do totoal em caixa; -->
            <b-td>
              <input disabled v-model="oldCaixa.id" type="text" class="form-control text-left">
            </b-td>
            <b-td>
              <input v-model="suprimento.nome" type="text" class="form-control text-left">
            </b-td>
            <b-td>
              <multiselect v-model="select_tipo" :options="pagamentos" label="tiponome"
                @select="select_tipo_pagemnto($event)">
              </multiselect>
            </b-td>
            <b-td>
              <input v-model="valor_suprimento" type="text" class="form-control text-right"
                @input="formatInput_valor_supriemnto()">
            </b-td>
            <b-td>
            </b-td>
            <b-td>
              <button class="btn btn-secondary" @click="post_Suprimento()">incluir</button>
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
      <hr>
      <div class="card">
        <div class="card-body">
          <h5>Suprimentos incluídos</h5>
        </div>
        <div>
          <div>
            <b-table striped hover :items="vetor_com_exluir" responsive
              :fields="fields"
            >
              <template #cell(excluir)="row">
                <button @click="delete_Suprimento(row.item)" class="btn btn-danger">Excluir</button>
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div>
      </div>
    </div>
  </b-modal>
</template>